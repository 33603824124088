@import "/src/_styleguide/variables.scss";
.step-car-calculator {
  .ant-collapse {
    border-radius: 10px;
    background: transparent;
    margin-bottom: 0;
    overflow: hidden;
    margin-top: 32px;
    .ant-collapse-content {
      background-color: transparent;
      .ant-collapse-content-box {
        padding: 24px !important;
        .app-form {
          margin-top: 0;
          margin-bottom: 0;
        }
        button {
          margin-top: 16px;
        }
      }
    }
    > .ant-collapse-item {
      background: #fff;
      transition: background-color 0.3s ease, border 0.3s ease;
      .ant-collapse-header-text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        color: #006295;
      }
      &.ant-collapse-item-active {
        background: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
      > .ant-collapse-header {
        padding: 16px 16px !important;
      }
    }
  }

  .blackbook-results {
    margin-top: 24px;
    background: #f0f0f0;
    padding: 16px 24px;
    border-radius: 16px;
    h2 {
      margin-top: 0;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #dedede;
    }
  }
}
