@import '/src/_styleguide/variables.scss';

.sky-break {
  display: block;
  content: '';
  clear: both;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  margin: 32px 0;
  background: #e4e6e9;
}