@import "/src/_styleguide/variables.scss";

h1,
h2,
h3,
h4,
h5 {
  &.sky-header {
    font-family: $font-header;
    margin: 0;
    color: #000000;
  }
}

/* Handle default headings */
h1.sky-header {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 1.25;
  color: $primary;
}

h2.sky-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
}

h3.sky-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
}

h4.sky-header {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
}

h5.sky-header {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.25;
}
