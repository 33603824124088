@import "/src/_styleguide/variables.scss";

.step-success {
  margin-top: 24px;
  .app-form {
    margin-top: 0;
  }
  .title-banner {
    margin: 0 24px;
    .success-banner {
      text-align: center;
      width: 100%;
      img {
        overflow: visible;
        max-width: 100% !important;
      }
    }
    .failure-banner {
      text-align: center;
      width: 100%;
      svg {
        width: 100px;
        height: 100px;
        color: #f39c12;
      }
    }
    h2 {
      color: $primary;
      margin: 16px 0 0 0;
      text-align: center;
    }
  }
  .success-info {
    margin: 16px 0 24px 0;
    color: #000;
    text-align: center;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .success-agreement {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.success-pdf {
    margin: 0 0 40px 0;
    background: #fff !important;
    border: 2px solid #000 !important;
    color: #000 !important;
    font-family: $font-title;
    font-weight: 500;
    &.barn-btn:hover,
    &.barn-btn:focus {
      background: #ededed !important;
      border: 2px solid #000 !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 8px;
    }
  }
  .next-steps {
    display: inline-flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid #efece5;
    background: #f8f6f2;
    gap: 26px;
    > h4 {
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0 0 8px 0;
    }
    > .next-step {
      display: flex;
      align-items: flex-start;
      svg {
        width: 24px;
        height: 24px;
        color: #b5a7e9;
        margin-right: 12px;
        min-width: 24px;
      }
      > div {
        flex: 1;
        h5 {
          color: #000;
          font-family: $font-title;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 122.222% */
          margin: 0;
        }
        p {
          color: #797979;
          font-family: $font-title;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 146.667% */
          margin: 8px 0 0 0;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-large) {
  .step-success {
    .title-banner {
      margin-left: 0;
      margin-right: 0;
    }
    .success-banner img {
      width: 100%;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .step-success {
    .title-banner {
      margin-left: 0;
      margin-right: 0;
    }
    .success-banner img {
      width: 100%;
    }
  }
}
