@import "/src/_styleguide/variables.scss";

button.sky-btn {
  font-family: $font-header;
  font-style: normal;
  font-weight: 600;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #d7dde9;
  &:hover {
    color: #090e15;
    background: #f5f7fb;
    border: 1px solid #d7dde9;
  }
  &:focus {
    color: #090e15;
    background: #e7ecf4;
    border: 1px solid #e7ecf4;
  }
  &[disabled] {
    opacity: 1 !important;
    color: #adb5c5 !important;
    background: #e7ecf4 !important;
    border: 1px solid #e7ecf4 !important;
  }
}

/* HANDLE TYPES */
button.sky-btn {
  &.btn-primary {
    background: $primary;
    border: 1px solid $primary;
    color: #fff;
    &:hover {
      background: $primary !important;
      color: #fff !important;
      opacity: 0.9 !important;
    }
    &:focus {
      background: $primary !important;
      color: #fff !important;
      opacity: 0.8 !important;
    }
    &[disabled] {
      opacity: 0.2 !important;
      color: #fff !important;
      background: #090e15 !important;
      border: 1px solid #090e15 !important;
    }
  }
  &.btn-secondary {
    background: #e7ecf4;
    border: 1px solid #e7ecf4;
    color: #090e15;
    &:hover {
      color: #090e15 !important;
      background: #d7dde9 !important;
      border: 1px solid #d7dde9 !important;
    }
    &:focus {
      color: #090e15 !important;
      background: #c4cbd9 !important;
      border: 1px solid #c4cbd9 !important;
    }
    &[disabled] {
      opacity: 1 !important;
      color: #adb5c5 !important;
      background: #f5f7fb !important;
      border: 1px solid #f5f7fb !important;
    }
  }
  &.btn-plain {
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #090e15;
    &:hover {
      color: #090e15;
      background: #f5f7fb;
      border: 1px solid #f5f7fb;
    }
    &:focus {
      color: #090e15;
      background: #e7ecf4;
      border: 1px solid #e7ecf4;
    }
    &[disabled] {
      opacity: 1 !important;
      color: #adb5c5 !important;
      background: #ffffff !important;
      border: 1px solid #ffffff !important;
    }
  }
  &.btn-danger {
    background: #fbece7;
    border: 1px solid #fbece7;
    color: #b34043;
    &:hover {
      color: #b34043;
      background: #ebdcd7;
      border: 1px solid #ebdcd7;
    }
    &:focus {
      color: #b34043;
      background: #ebdcd7;
      border: 1px solid #ebdcd7;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #b34043 !important;
      background: #fbece7 !important;
      border: 1px solid #fbece7 !important;
    }
  }
  &.btn-warning {
    background: #fdfaef;
    border: 1px solid #efdfc4;
    border-radius: 10px;
    color: #c8803e;
    svg {
      color: #c8803e;
    }
    &:hover {
      color: #c8803e;
      background: #efdfc4;
      border: 1px solid #efdfc4;
    }
    &:focus {
      color: #c8803e;
      background: #efdfc4;
      border: 1px solid #efdfc4;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #c8803e !important;
      background: #fdfaef !important;
      border: 1px solid #fdfaef !important;
    }
  }
  &.btn-success {
    background: #eef9ec;
    border: 1px solid #c1ddbb;
    color: #577e4e;
    svg {
      color: #577e4e;
    }
    &:hover {
      color: #577e4e;
      background: #c1ddbb;
      border: 1px solid #c1ddbb;
    }
    &:focus {
      color: #577e4e;
      background: #c1ddbb;
      border: 1px solid #c1ddbb;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #577e4e !important;
      background: #eef9ec !important;
      border: 1px solid #eef9ec !important;
    }
  }
  &.btn-info {
    background: #d2d0fb;
    border: 1px solid #d2d0fb;
    color: #3e3993;
    &:hover {
      color: #3e3993;
      background: #e8e7fd;
      border: 1px solid #e8e7fd;
    }
    &:focus {
      color: #3e3993;
      background: #e8e7fd;
      border: 1px solid #e8e7fd;
    }
    &[disabled] {
      opacity: 0.4 !important;
      color: #3e3993 !important;
      background: #fbece7 !important;
      border: 1px solid #fbece7 !important;
    }
  }
}
