@import "/src/_styleguide/variables.scss";

.sky-label {
  display: block;
  color: $secondary;
  font-family: $font-main;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  i {
    opacity: 0.55;
    text-decoration: none;
    font-style: normal;
    font-size: 13px;
  }
}
