/* FONTS */
$font-title: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-header: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-main: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

/* COLORS */
$background: #f0f2f6;
$white: #ffffff;
$primary: #006295;
$secondary: #292559;
$text: #7a7a7a;
$accent: #61ce70;

/* Media Queries */
$breakpoint-extra: 1200px;
$breakpoint-large: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 664px;
