@import "/src/_styleguide/variables.scss";

.app-box {
  width: auto;
  max-width: 600px;
  background: $white;
  padding: 48px;
  border-radius: 24px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  margin-top: 40px;
  margin-bottom: 40px;
  > img {
    position: fixed;
    top: 24px;
    left: 24px;
    height: 40px;
  }
  .app-headline {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 72px;
    width: 100%;
    background: #fff;
    z-index: 1000;

    @media only screen and (max-width: 768px) {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    }

    > img {
      position: absolute;
      top: 16px;
      left: 16px;
      height: 40px;
    }
  }
}

.app-form {
  margin-top: 32px;
  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    > div {
      flex: 1;
    }
  }
  .form-item,
  .form-row {
    margin-bottom: 16px;
  }
  .ant-checkbox-wrapper {
    margin-top: 8px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .app-box {
    max-width: none;
    margin-top: 40px;
    margin-bottom: 0;
    border-radius: 0;
    padding: 48px 24px;
  }
  .app-form {
    .form-row,
    .form-item {
      flex-direction: column;
      gap: 0;
      > div {
        width: 100%;
        max-width: 100% !important;
        margin-bottom: 16px;
      }
    }
    .form-row {
      margin-bottom: 0;
    }
  }
}

.payment-toggle {
  margin-bottom: 24px;
  .ant-btn-group {
    display: flex;
    width: 100%;
    > button {
      height: 50px;
    }
  }
  .ant-btn-primary {
    color: #fff;
    font-family: $font-header;
    font-style: normal;
    font-weight: 600;
    background: $secondary;
    border-color: $secondary;
    box-shadow: none;
  }
  .ant-btn-default {
    font-family: $font-header;
    font-style: normal;
    font-weight: 600;
    box-shadow: none;
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: $secondary;
    background: $secondary;
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    border-color: #d9d9d9;
    background: #d9d9d9;
  }
}

.pageSlider-exit > .page {
  z-index: 1;
}

.pageSlider-enter > .page {
  z-index: 10;
}

.pageSlider-enter.left > .page {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right > .page {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.left > .page {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.right > .page {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  transition: all 800ms ease-in-out;
}

.form-item.policy {
  font-size: 14px;
  .ant-checkbox-wrapper {
    margin-right: 8px;
  }
  .policy-link {
    color: #000;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.policy-modal {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 0;
  color: #333;
  h1,
  h2,
  h3 {
    color: #0056b3;
  }
  a {
    color: #0056b3;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  ul {
    margin-left: 0;
    list-style-type: decimal;
  }

  .barn-modal-body {
    margin-top: 0 !important;
    > h1 {
      margin-top: 0 !important;
    }
  }
}
