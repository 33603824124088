@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_assets/fonts/fonts.scss";
@import "/src/_styleguide/variables.scss";

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #090e15;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

#root {
  width: 100%;
}

body.barn-dark {
  background-color: $dark-1000;
}
body.barn-light {
  background-color: $light-1000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000000s ease-in-out 0s;
}

.barn-light {
  .green-btn {
    background: #a2d683 !important;
    border: 1px solid #a2d683 !important;
    color: #000 !important;
    font-family: $font-title;
    font-weight: 500;
    &.barn-btn:hover,
    &.barn-btn:focus {
      background: #92c673 !important;
      border: 1px solid #92c673 !important;
    }
    &.barn-btn:disabled {
      opacity: 1 !important;
      color: #9d9d9d !important;
      background: #ececec !important;
      border: 1px solid #ececec !important;
    }
  }
}
