.sky-actions {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  gap: 12px;
  > a {
    flex: 1;
  }
}