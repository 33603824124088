@import "/src/~@centrate-io/barn/dist/variables";

.auth-login {
  min-width: 240px;
  width: 320px;
  padding: 40px;
  border-radius: 24px;
  background: #fff;
  border: 1px solid #eeedec;
  box-shadow: 0 0 100px -30px rgba(0, 0, 0, 0.1);
  .line {
    margin-bottom: 20px;
  }
  button {
    margin-top: 32px;
    font-weight: 600 !important;
  }
  .auth-info {
    text-align: center;
    margin-bottom: 32px;
  }
  .login-logo {
    height: 40px;
  }
}
