@import "/src/_styleguide/variables.scss";

/* Progress bar */
.sky-progress {
  margin-bottom: 24px;
  .ant-progress-bg {
    background-color: $accent;
  }
}

.ant-input-number-input {
  font-size: 16px !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: $secondary;
  box-shadow: none !important;
  outline: 0;
  background-color: #ffffff;
}
.ant-input-outlined:hover {
  border-color: $secondary;
  background-color: #ffffff;
}

.ant-input-number-outlined:focus,
.ant-input-number-outlined:focus-within {
  border-color: $secondary;
  box-shadow: none !important;
  outline: 0;
  background-color: #ffffff;
}
.ant-input-number-outlined:hover {
  border-color: $secondary;
  background-color: #ffffff;
}

.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: $secondary;
}

.ant-select {
  .ant-select-selection-search-input {
    font-size: 16px;
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}
.ant-message-notice {
  > .ant-message-notice-content {
    background: #000 !important;
    box-shadow: none !important;
    border-radius: 10px;
    padding: 8px 16px;

    .ant-message-custom-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 12px;
        color: #fff;
        width: 18px;
        height: 18px;
      }
      span {
        font-family: $font-main;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
      }
    }
  }
}
