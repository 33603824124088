@-webkit-keyframes loadpaypath {
  0% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }
}

@keyframes loadpaypath {
  0% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.3;
  }
}

#paypath-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  > img {
    width: 120px;
    transform: translate(-50px, -50px);
    -webkit-animation: loadpaypath 2s infinite ease-in-out;
    -o-animation: loadpaypath 2s infinite ease-in-out;
    -ms-animation: loadpaypath 2s infinite ease-in-out;
    -moz-animation: loadpaypath 2s infinite ease-in-out;
    animation: loadpaypath 2s infinite ease-in-out;
  }
}
